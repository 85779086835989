import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getCurrentUser, signOut } from '@aws-amplify/auth';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Link,
  Grid,
  Paper
} from '@mui/material';

const MainPage: React.FC = () => {
  const [user, setUser] = React.useState<any>(null);

  React.useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (err) {
      setUser(null);
    }
  }

  async function handleSignOut() {
    try {
      await signOut();
      setUser(null);
    } catch (err) {
      console.error('Error signing out:', err);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Qosti
          </Typography>
          {user ? (
            <>
              <Typography variant="body1" sx={{ mr: 2 }}>
                Welcome, {user.username}
              </Typography>
              <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
            </>
          ) : (
            <>
              <Button color="inherit" component={RouterLink} to="/signin">Sign In</Button>
              <Button color="inherit" component={RouterLink} to="/signup">Sign Up</Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h4" gutterBottom>
                Welcome to Qosti
              </Typography>
              <Typography variant="body1" paragraph>
                Qosti is a powerful web application with a Python-based backend implementing CQRS pattern and event sourcing, and a React frontend.
              </Typography>
              {!user && (
                <Typography variant="body1">
                  Please <Link component={RouterLink} to="/signin">sign in</Link> or <Link component={RouterLink} to="/signup">sign up</Link> to access all features.
                </Typography>
              )}
            </Paper>
          </Grid>

          {user && (
            <>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h5" gutterBottom>
                    Your Dashboard
                  </Typography>
                  <Typography variant="body1">
                    Here you can display user-specific information and quick actions.
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h5" gutterBottom>
                    Recent Activity
                  </Typography>
                  <Typography variant="body1">
                    You can show recent user activity or system updates here.
                  </Typography>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default MainPage;
