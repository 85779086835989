// src/hooks/useAuth.ts
import { useState, useEffect } from 'react';
import { getCurrentUser, signIn, signUp, SignInInput, SignInOutput, SignUpInput, SignUpOutput } from '@aws-amplify/auth';

interface AuthState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

interface UseAuthReturn extends AuthState {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  signIn: (credentials: SignInInput) => Promise<SignInOutput>;
  signUp: (credentials: SignUpInput) => Promise<SignUpOutput>;
}

export const useAuth = (): UseAuthReturn => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);

  const setAuthState = ({ isAuthenticated, isAuthenticating }: AuthState) => {
    setIsAuthenticated(isAuthenticated);
    setIsAuthenticating(isAuthenticating);
  }

  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setAuthState({ isAuthenticated: true, isAuthenticating: false });
    } catch (error) {
      console.error('Error checking auth state:', error);
      setAuthState({ isAuthenticated: false, isAuthenticating: false });
    }
  };

  return {
    isAuthenticated,
    isAuthenticating,
    setIsAuthenticated,
    signIn,
    signUp
  };
};
