import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

interface LoadingComponentProps {
  message?: string;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ message = 'Loading...' }) => {
  return (
    <Box
      className="flex flex-col items-center justify-center h-screen"
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <CircularProgress size={60} className="mb-4" />
      <Typography variant="h6" className="text-gray-600">
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingComponent;

