// src/components/layouts/AuthorizedLayout.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

interface AuthorizedLayoutProps {
  isAuthenticated: boolean;
}

const AuthorizedLayout: React.FC<AuthorizedLayoutProps> = ({ isAuthenticated }) => {
  if (!isAuthenticated) {
    return <Navigate to="/account/signin" replace />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default AuthorizedLayout;
