// src/components/Footer/Footer.tsx
import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box component="footer" className="bg-gray-100 py-6 mt-auto">
      <Container maxWidth="lg">
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} Qosti. All rights reserved.
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center" className="mt-2">
          <Link color="inherit" href="https://material-ui.com/" className="hover:underline">
            Built with Material-UI
          </Link>
          {' and '}
          <Link color="inherit" href="https://tailwindcss.com/" className="hover:underline">
            Tailwind CSS
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

