// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';
import AuthorizedLayout from './components/Layouts/AuthorizedLayout';
import UnauthorizedLayout from './components/Layouts/UnauthorizedLayout';
import { useAuth } from './hooks/Auth/useAuth';
import LoadingComponent from './components/Layouts/components/LoadingComponent';

import MainPage from './pages/MainPage';
import SingInPage from './pages/Authorization/SignInPage';
import SignUpPage from './pages/Authorization/SignUpPage';


function App() {
  const { isAuthenticated, isAuthenticating } = useAuth();

  if (isAuthenticating) {
    return <LoadingComponent />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="flex flex-col min-h-screen">
          <main className="flex-grow">
            <Routes>
              <Route element={<AuthorizedLayout isAuthenticated={isAuthenticated} />}>
                <Route path="/" element={<MainPage />} />
              </Route>

              <Route element={<UnauthorizedLayout />}>
                <Route path="/account/signin" element={<SingInPage />} />
                <Route path="/account/signup" element={<SignUpPage />} />
              </Route>
            </Routes>
          </main>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
